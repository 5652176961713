import axios from "axios";

let language;
if (window.localStorage.getItem("i18nextLng")) {
  language = window.localStorage.getItem("i18nextLng").substr(0, 2);
}
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept-Language"] = language;

let blockingOtherRequest = false;

const API_URL =
  process.env.REACT_APP_NODE_SERVER.trim() || "http://localhost:5000";

//response interceptor to refresh token on receiving token expired error
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      if (blockingOtherRequest) {
        // just try it again in a second
        setTimeout(function () {
          return axios(originalRequest);
        }, 1000);
      } else if (error.response.data.refreshtoken) {
        blockingOtherRequest = true;
        const payload = { refreshtoken: error.response.data.refreshtoken };
        const url = `${API_URL}/auth/refresh_token`;
        // dont use the postRequest function here, otherwise you kill the success and error function callbacks
        return axios
          .post(url, payload, {
            withCredentials: true,
          })
          .then((res) => {
            if (res.status.toString().substr(0, 1) === "2") {
              return axios(originalRequest);
            }
          })
          .catch((err) => {
            blockingOtherRequest = false;
            Logger("error", err.toString() + " | " + url, 90000);
          });
      } else {
        blockingOtherRequest = false;
        originalRequest._retry = false;
      }
    }
    return Promise.reject(error);
  }
);

/**
 * @url as string to the api
 * @data as array of items which defines the post data
 * @success as function, what to do if request was succesfull
 * @error as function, what to do if request failed
 */
export function postRequest(url, data, success, error) {
  const cancelTokenSource = axios.CancelToken.source();
  axios
    .post(`${API_URL}${url}`, data, { withCredentials: true, cancelToken: cancelTokenSource.token })
    .then(function (res) {
      blockingOtherRequest = false;
      if (success) {
        success(res);
      }
    })
    .catch((err) => {
      if (error && !blockingOtherRequest) {
        if (err.toString() === "Cancel") {
          return;
        }
        if (err && err.response && err.response.data.msg) {
          error(err.response.data.msg);
          Logger(
            "warning",
            err.response.data.msg.toString() + " | " + url,
            90001
          );

        } else {
          error(err.message);
          Logger("error", err.toString() + " | " + url, 90002);
        }
      }
    });
  return cancelTokenSource;
}

/**
 * @url as string to the api
 * @success as function, what to do if request was succesfull
 * @error as function, what to do if request failed
 */
export function getRequest(url, success, error) {
  const cancelTokenSource = axios.CancelToken.source();
  axios
    .get(`${API_URL}${url}`, { withCredentials: true, cancelToken: cancelTokenSource.token })
    .then(function (res) {
      blockingOtherRequest = false;
      if (success) {
        success(res);
      }
    })
    .catch((err) => {
      if (error && !blockingOtherRequest) {
        if (err.toString() === "Cancel") {
          return;
        }
        if (err && err.response && err.response.data.msg) {
          error(err.response.data.msg);
          Logger(
            "warning",
            err.response.data.msg.toString() + " | " + url,
            90003
          );
        } else {
          error(err.message);
          Logger("error", err.toString() + " | " + url, 90004);
        }
      }
    });
  return cancelTokenSource;
}

/**
 * @file from file input type
 * @success as function, what to do if request was succesfull
 * @error as function, what to do if request failed
 * @processingDone as function, what to do if image processing is done
 */
export async function uploadFile(file, success, error, processingDone) {
  if (!file) return null;

  const formData = new FormData();
  formData.append("file", file);

  // getting meta data from the file
  const payload = {
    originalname: file.name,
    mimetype: file.type,
    size: file.size,
  };

  const url = `/inc/upload.php`;

  // TODO for localdev, set imageupload to false
  const enableUpload = true;
  const createSizes = ["all"];

  // sending the file meta data to the backend and generate a new imageId
  postRequest(
    "/pictures/getImageId",
    payload,
    function (res) {
      if (enableUpload) {
        formData.append("imageName", res.data.imageId);

        // sending the image file to the webserver
        // the image will be saved under /image/ imageID . extension
        axios({
          method: "post",
          url: url,
          data: formData,
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
          .then(function (res) {
            for (let i = 0; i < createSizes.length; i++) {
              const formData = new FormData();
              formData.append("imageId", res.data.imageId);
              formData.append("imageThumbnail", res.data.imageName);
              formData.append("extension", res.data.extension);
              formData.append("size", createSizes[i]);
              axios({
                method: "post",
                url: url,
                data: formData,
                headers: {
                  "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
                },
              }).then(function (res) {
                if (processingDone) {
                  processingDone(res);
                }
              });
            }
            if (success) {
              success(res);
            }
          })
          .catch((err) => {
            if (error) {
              if (err && err.response && err.response.data.msg) {
                Logger(
                  "warning",
                  err.response.data.msg.toString() + " | " + url,
                  90005
                );
                error(err.response.data.msg);
              } else {
                Logger("error", err.toString() + " | " + url, 90006);
                error(err);
              }
            }
          });
      } else {
        if (success) {
          success(res);
        }
        setTimeout(function () {
          if (processingDone) {
            processingDone(res);
          }
        }, 3000);
      }
    },
    function (err) {
      if (error) {
        if (err && err.response && err.response.data.msg) {
          Logger(
            "warning",
            err.response.data.msg.toString() + " | " + url,
            90007
          );
          error(err.response.data.msg);
        } else {
          Logger("error", err.toString() + " | " + url, 90008);
          error(err);
        }
      }
    }
  );
}

/**
 * @tpye as string, info, warning, error
 * @message as string, whats the message
 * @code as numnber, an unique numnber
 */
export async function Logger(type, message, code) {
  const data = { type, message, code };

  axios
    .post(`${API_URL}/log`, data)
    .then(function () {
      console.log(type + ": " + message + " | Code: " + code.toString());
    })
    .catch((err) => {
      if (err && err.response && err.response.data) {
        console.log(err.response.data.msg);
      } else {
        console.log(err);
      }
    });
}
