import { extendTheme } from "@chakra-ui/react";

// https://smart-swatch.netlify.app/ for color palette
const newTheme = extendTheme({
  useSystemColorMode: true,
  fonts: {
    heading: "Raleway-Regular",
    body: "Montserrat-Regular",
  },
  colors: {
    brand: {
      50: "#deffe6",
      100: "#afffc2",
      200: "#14dd11", // "#7eff9c",
      300: "#14dd11",
      400: "#20ff4f",
      500: "#0ce637",
      600: "#00b329",
      700: "#00801c",
      800: "#004d0f",
      900: "#001c00",
    },
    gray: {
      50: "#F7FAFC",
      100: "#d9d9d9",
      200: "#bfbfbf",
      300: "#a6a6a6",
      400: "#8c8c8c",
      500: "#737373",
      600: "#595959",
      700: "#404040",
      800: "#262626",
      900: "#120b0d",
    },

    red: {
      50: "#FFF5F5",
      100: "#FED7D7",
      200: "#E53E3E",
      300: "#FC8181",
      400: "#F56565",
      500: "#E53E3E",
      600: "#C53030",
      700: "#9B2C2C",
      800: "#822727",
      900: "#63171B",
    },
  },

  components: {
    Heading: {
      defaultProps: {
        size: "lg",
      },
    },

    Text: {
      baseStyle: {
        fontWeight: "normal"
      }
    },

    Button: {
      baseStyle: {
        fontWeight: "normal"
      }
    },

    Input: {
      defaultProps: {
        focusBorderColor: 'brand.600',
      },
    },

    Textarea: {
      defaultProps: {
        focusBorderColor: 'brand.600',
      },
    },

    PrimaryButton: {
      baseStyle: () => ({
        variant: "solid",
        color: "white",
        bgGradient: "linear(to-r, brand.600, brand.600, brand.300)",
        filter: "drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4))",
        transition: "0.5s",
        _hover: {
          bgGradient: "linear(to-r, brand.300, brand.600, brand.600)",
        },
      }),
    },
    SecondaryButton: {
      baseStyle: () => ({
        variant: "outline",
        color: "brand.600"
      }),
    },
    AlertButton: {
      baseStyle: () => ({
        variant: "solid",
        color: "white",
        bgGradient: "linear(to-r, red.600, red.600, red.300)",
        filter: "drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4))",
        transition: "0.5s",
        _hover: {
          bgGradient: "linear(to-r, red.300, red.600, red.600)",
        },
      }),
    },
    RoundButton: {
      baseStyle: () => ({
        color: "white",
        bgGradient: "linear(to-r, brand.600, brand.600, brand.300)",
        filter: "drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4))",
        transition: "0.5s",
        _hover: {
          bgGradient: "linear(to-r, brand.300, brand.600, brand.600)",
        },
      }),
    },
  },
});

export default newTheme;
