import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import UserContext from "../context/userContext";

export default function PrivateRoute(props) {
  const { userData, isUserLoading } = useContext(UserContext);

  const { component: Component, ...rest } = props;

  if (isUserLoading) {
    return null;
  }

  if (userData && userData.user) {
    return <Route {...rest} render={(props) => <Component {...props} />} />;
  } else {
    return <Redirect to="/login" />;
  }
}

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};
