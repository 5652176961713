import React from "react"
import PropTypes from 'prop-types'
import {
  Flex,
  Spinner,
} from "@chakra-ui/react";

export default function LoadingCircle({ bgColor, color }) {
  const defaultColor = "gray.100";
  const emptyColor = "transparent";

  return (
    <Flex
      width="100%"
      height="100%"
      align="center"
      justifyContent="center"
      bgColor={bgColor ? bgColor : null}
    >
      <Spinner
        color={color ? color : defaultColor}
        thickness="3px"
        speed="0.65s"
        emptyColor={bgColor ? bgColor : emptyColor}
        size="lg"
      />
    </Flex>
  );
}

LoadingCircle.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
};

