import React, { useContext } from "react";
import PropTypes from 'prop-types';
import { Flex, Link, IconButton, useColorModeValue } from "@chakra-ui/react";
import { Link as Reachlink, useHistory } from "react-router-dom";
import {
  AiOutlineHome,
  AiOutlineSearch,
  AiFillHome,
  AiFillPlusCircle,
} from "react-icons/ai";
import {
  IoChatbubbleOutline,
  IoChatbubble,
  IoAddCircleOutline,
} from "react-icons/io5";
import Badge from "../components/Social/Badge";
import { goToHome } from "../utils/Utils";
import userContext from "../context/userContext";
import { MdPerson, MdPersonOutline } from "react-icons/md";

const FooterMobile = ({ pathname }) => {
  const { userData } = useContext(userContext);
  const history = useHistory();
  let profileUrl = "/login";
  if (userData && userData.user && userData.user.id) {
    profileUrl = "/profile/" + userData.user.id;
  }

  let homeActive = false;
  if (pathname === "/" || pathname === "/home") {
    homeActive = true;
  }

  let createActive = false;
  if (pathname === "/createrecipe") {
    createActive = true;
  }

  let profileActive = false;
  if (pathname.indexOf("/profile") >= 0) {
    profileActive = true;
  }

  let chatActive = false;
  if (pathname === "/chatrooms" || pathname.indexOf("/chat/") >= 0) {
    chatActive = true;
  }

  return (
    <Flex
      bg={useColorModeValue("rgb(255 255 255 / 90%)", "rgb(64 64 64 / 90%)")}
      width="100vw"
      alignItems="center"
      justifyContent={"space-evenly"}
      borderStyle="solid"
      borderTopWidth="1px"
      id="footer"
      position="absolute"
      bottom="0"
      zIndex="10"
      backdropFilter="blur(4px)"
      pt={"1px"}
      pb={"calc(env(safe-area-inset-bottom) - 10px)"}
    >
      <Link
        as={Reachlink}
        to="/"
        onClick={(e) => {
          goToHome(e, pathname, history);
        }}
        filter="none"
      >
        <IconButton
          variant="ghost"
          colorScheme="gray"
          aria-label="Home"
          fontSize="28px"
          icon={homeActive ? <AiFillHome /> : <AiOutlineHome />}
          _hover={{ background: "transparent" }}
          _active={{ background: "transparent" }}
        />
      </Link>
      <Link as={Reachlink} to="/search" mx="10px">
        <IconButton
          variant="ghost"
          colorScheme="gray"
          aria-label="Search"
          fontSize="28px"
          icon={<AiOutlineSearch />}
          _hover={{ background: "transparent" }}
          _active={{ background: "transparent" }}

        />
      </Link>
      <Link as={Reachlink} to="/createrecipe" mx="10px">
        <IconButton
          variant="ghost"
          colorScheme="gray"
          aria-label="Create"
          fontSize="28px"
          icon={createActive ? <AiFillPlusCircle /> : <IoAddCircleOutline />}
          mx="5px"
          _hover={{ background: "transparent" }}
          _active={{ background: "transparent" }}
        />
      </Link>
      <Link as={Reachlink} to="/chatrooms" mx="10px">
        <IconButton
          variant="ghost"
          colorScheme="gray"
          aria-label="Chat"
          fontSize="28px"
          icon={chatActive ? <IoChatbubble /> : <IoChatbubbleOutline />}
          _hover={{ background: "transparent" }}
          _active={{ background: "transparent" }}
        />
        <Badge />
      </Link>
      <Link as={Reachlink} to={profileUrl} >
        <IconButton
          variant="ghost"
          colorScheme="gray"
          aria-label="Profile"
          fontSize="28px"
          icon={profileActive ? <MdPerson /> : <MdPersonOutline />}
          _hover={{ background: "transparent" }}
          _active={{ background: "transparent" }}
        />
      </Link>
    </Flex>
  );
};
export default FooterMobile;

FooterMobile.propTypes = {
  pathname: PropTypes.string,
};
