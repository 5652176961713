import React, { useContext, useEffect, useRef } from "react";
import PropTypes from 'prop-types';
import HeaderDesktop from "./HeaderDesktop";
import HeaderMobile from "./HeaderMobile";
import FooterMobile from "./FooterMobile";
import { Flex, useColorMode, useColorModeValue } from "@chakra-ui/react";
import SizeContext from "../context/sizeContext";
import UserContext from "../context/userContext";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import ContentBox from "./ContentBox";
import { rewriteMetaTags } from "../utils/Utils";

const Layout = (props) => {
  const { windowSize } = useContext(SizeContext);
  const { colorMode, toggleColorMode } = useColorMode();
  const { userData } = useContext(UserContext);
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const bgFeed = useColorModeValue("gray.50", "gray.800");
  const { t } = useTranslation();
  const content = useRef(null);

  // remove stuff after second / in url
  const cleanupPathname = (route) => {
    let title = route.substring(1, pathname.length);
    // if pathname got a 2nd / like profile or recipe, we split it and save the correct pathname
    if (title.indexOf("/", 1) !== -1) {
      title = title.split("/");
      title = title[0];
    }
    return title;
  };

  // dont you useEffect - it causes some weired rerendering which causes issues on diff. pages for ex. chat
  const cleanedUpPathname = cleanupPathname(pathname);

  if (cleanedUpPathname === "" || cleanedUpPathname === "home" || cleanedUpPathname === "recipe") {
    rewriteMetaTags({ title: t("appTitle"), themeColor: "#262626" });
  } else {
    rewriteMetaTags({ title: t(cleanedUpPathname), themeColor: "#00b329" });
  }

  let showBorder = windowSize.isMobile
  let showHeading = !windowSize.isMobile
  let padding = 4
  let isFeed = false;
  let title = cleanedUpPathname;
  let bgGradient = "linear-gradient(90deg, var(--chakra-colors-brand-600) 0%, var(--chakra-colors-brand-600) 100%)"
  let borderSize = "sm"
  if (cleanedUpPathname === "recipe") {
    showBorder = false;
    showHeading = false;
    padding = 0;
  }
  if (cleanedUpPathname === "chat") {
    title = ""
    padding = 0;
  }
  if (cleanedUpPathname === "chat" || cleanedUpPathname === "search") {
    borderSize = "smgr"
  }
  if (cleanedUpPathname === "createrecipe" || cleanedUpPathname === "updaterecipe") {
    borderSize = "lg"
  }
  if (cleanedUpPathname === "" || cleanedUpPathname === "home") {
    showBorder = false;
    isFeed = true;
  }


  useEffect(() => {
    // handling color Mode Switch from User
    function handleChange(dark = true) {
      let newColorMode = "light";
      if (dark) {
        newColorMode = "dark";
      }

      if (colorMode !== newColorMode) {
        toggleColorMode();
      }
    }

    try {
      if (window.matchMedia) {
        window
          .matchMedia("(prefers-color-scheme: dark)")
          .addEventListener("change", (event) => handleChange(event.matches));
      }
    } catch {
      return "not supported";
    }

    return () => {
      window.removeEventListener("change", handleChange);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorMode]);

  useEffect(() => {
    // get colorSchema from device
    function getDeviceColorSceme() {
      try {
        if (
          window.matchMedia &&
          window.matchMedia("(prefers-color-scheme: dark)").matches
        ) {
          // dark mode
          return "dark";
        } else {
          return "light";
        }
      } catch {
        return "light";
      }
    }

    // initial value from userData
    if (userData && userData.user) {
      // if colorMode differs from user preferences, then toggle
      const userColorMode = userData.user.colorMode;
      const userLanguage = userData.user.language;
      const language = window.localStorage.getItem("i18nextLng");
      if (userColorMode && userColorMode !== "") {
        if (userColorMode === "system") {
          const systemColorMode = getDeviceColorSceme();
          if (colorMode !== systemColorMode) {
            if (toggleColorMode) {
              toggleColorMode();
            }
          }
        } else {
          if (colorMode !== userColorMode) {
            if (toggleColorMode) {
              toggleColorMode();
            }
          }
        }
      }
      if (userLanguage && userLanguage !== "") {
        if (language !== userLanguage) {
          i18n.changeLanguage(userLanguage);
        }
      }
    }
  });

  useEffect(() => {
    // Scrollposition fix for ugly overshoot on safari #301
    const scrollContainer = content;

    function handleScroll() {
      let scroll = scrollContainer.current.scrollTop;
      if (scroll > 10 && showBorder) {
        document.getElementById("header").style.boxShadow = "0px 1px 2px rgb(0 0 0 / 40%)"
      } else {
        document.getElementById("header").style.boxShadow = "none"
      }
    }

    if (scrollContainer) {
      scrollContainer.current.addEventListener("scroll", handleScroll)
    }

    return () => {
      if (scrollContainer?.current) {
        scrollContainer.current.removeEventListener("change", handleScroll);
      }
    };


  }, [content, pathname]);

  return (
    <Flex
      h={windowSize.height}
      w="100vw"
      direction="column"
      id="layout"
      alignItems="center"
      overflow="hidden"
    >
      {windowSize.isMobile ? (
        <HeaderMobile title={title} cleanedUpPathname={cleanedUpPathname} />
      ) : (
        <HeaderDesktop pathname={pathname} cleanedUpPathname={cleanedUpPathname} />
      )}

      <Flex
        overflowY={"auto"}
        overflowX="hidden"
        height="100%"
        width="100%"
        id="content"
        className="content"
        p={windowSize.isMobile ? 0 : 4}
        bg={windowSize.isMobile && showBorder ? bgGradient : bgFeed}
        backgroundSize="100% 50%"
        backgroundRepeat="no-repeat"
        flexDir="column"
        ref={content}
      >
        {!isFeed ? (
          <ContentBox p={padding} showBorder={showBorder} borderSize={borderSize} showHeading={showHeading} title={title}>
            {props.children}
          </ContentBox>
        ) : (
          props.children
        )}

      </Flex>
      {windowSize.isMobile ? (
        <FooterMobile pathname={pathname} />
      ) : null}
    </Flex >
  );
};

export default React.memo(Layout);

Layout.propTypes = {
  children: PropTypes.node
};



