import React from "react"
import PropTypes from 'prop-types';
import UserContext from "../userContext";
import SizeContext from "../sizeContext";
import useCheckLoggedIn from "../../hooks/useCheckLoggedIn";
import useWindowResize from "../../hooks/useWindowSize";

const ContextProviders = ({ children }) => {
    /* 
        Make a Provider component that takes children instead of putting directly components in the Provider like this:
        So we can call the context in every component without causing a rerender
    */
    const { userData, setUserData, isUserLoading } = useCheckLoggedIn();
    const { windowSize } = useWindowResize();

    return (
        <UserContext.Provider value={{ userData, setUserData, isUserLoading }}>
            <SizeContext.Provider value={{ windowSize }}>
                {children}
            </SizeContext.Provider>
        </UserContext.Provider>
    )
}

export default ContextProviders

ContextProviders.propTypes = {
    children: PropTypes.node,
};