import { createStandaloneToast } from '@chakra-ui/react';
import theme from "../theme/theme";

export default function ErrorMessage(message) {
  const toast = createStandaloneToast({ theme });

  const id = message;
  if (!toast.isActive(id) && id && id !== "") {
    toast({
      id: id,
      description: message.toString(),
      status: 'error',
      duration: 2000,
      isClosable: true,
    });
  }
}
