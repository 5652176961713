import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from './en/translation.json';
import translationDE from './de/translation.json';

const resources = {
    en: {
        translation: translationEN
    },
    de: {
        translation: translationDE
    }
};
i18next
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        load: "languageOnly",
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });
export default i18next;