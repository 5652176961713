import React, { useContext, useRef } from "react";
import PropTypes from "prop-types";
import { Link as Reachlink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineMenu } from "react-icons/ai";
import {
  Avatar,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Box,
  Flex,
  Link,
  IconButton,
  VStack,
  Text,
  Heading,
  Icon,
  HStack,
} from "@chakra-ui/react";
import { AiOutlineSearch } from "react-icons/ai";
import {
  IoPersonOutline,
  IoChatbubblesOutline,
  IoCartOutline,
  IoCalendarNumberOutline,
  IoAddCircleOutline,
  IoHelpCircleOutline
} from "react-icons/io5";
import { MdLogin } from "react-icons/md";
import Badge from "../components/Social/Badge";
import Background from "../assets/HeaderBackground.svg";
import SpoonyCircle from "../assets/SpoonyCircle.js";
import NavLink from "../components/Profile/NavLink";
import userContext from "../context/userContext";
import { PrimaryButton } from "../theme/components/PrimaryButton";

// DRAWER
function UserMenu({ isDesktop, cleanedUpPathname }) {
  const { userData } = useContext(userContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  const { t } = useTranslation();
  const desktop = isDesktop ? isDesktop : false;

  return (
    <>
      {desktop && userData?.user ? (
        // Avatar Menu
        <Button
          as={Button}
          variant={"ghost"}
          cursor={"pointer"}
          _hover={{ background: "transparent" }}
          _active={{ background: "transparent" }}
          colorScheme="transparent"
          onClick={onOpen}
          display="flex"
          alignItems="center"
        >
          <Avatar
            size={"md"}
            src={
              userData.user.avatar
                ? "https://spoony.cooking/images/xsmall/" +
                userData.user.avatar._id +
                "." +
                userData.user.avatar.extension
                : ""
            }
            name={userData.user.username}
            bg={userData.user.color}
          />
          <Box p={1} as="span">
            {userData.user.username}
          </Box>
        </Button>
      ) : (
        <IconButton
          ref={btnRef}
          size="lg"
          fontSize="25px"
          variant="ghost"
          onClick={onOpen}
          icon={<AiOutlineMenu />}
          aria-label="Menu Button"
          _hover={{ background: "transparent" }}
          _active={{ background: "transparent" }}
          opacity="0.99"
        />
      )}

      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay>
          <DrawerContent
            bgImage={Background}
            bgRepeat={"no-repeat"}
            bgSize={"100%  135px"}
          >
            <DrawerHeader
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              p={4}
            >
              <Icon as={SpoonyCircle} w={10} h={10} />
              <Heading as="cite" size="md" fontWeight="semibold" color="white">
                {t("appTitle")}
              </Heading>
              <DrawerCloseButton
                variant="ghost"
                position="relative"
                color="white"
                top={0}
                right={0}
                _hover={{ background: "transparent" }}
                _active={{ background: "transparent" }}
              />
            </DrawerHeader>
            <DrawerBody>
              {/* If user is logged in, load avater, else load login sign up */}
              <>
                {userData?.user ? (
                  <VStack spacing={6} h="100%">
                    <VStack
                      as={Reachlink}
                      to={`/profile/${userData.user.id}`}
                      onClick={onClose}
                    >
                      <Avatar
                        size={"lg"}
                        src={
                          userData.user.avatar
                            ? "https://spoony.cooking/images/xsmall/" +
                            userData.user.avatar._id +
                            "." +
                            userData.user.avatar.extension
                            : ""
                        }
                        name={userData.user.username}
                        bg={userData.user.color}
                      />
                      <Text textAlign="center" fontWeight="semibold">
                        {userData.user.username}
                      </Text>
                    </VStack>
                    <VStack alignItems="flex-start" w="100%" spacing={4}>
                      <NavLink
                        isActive={cleanedUpPathname === "search" ? true : false}
                        linkIcon={AiOutlineSearch}
                        linkText={t("search")}
                        linkHref="/search"
                        onClick={onClose}
                      />
                      <NavLink
                        isActive={
                          cleanedUpPathname === "createrecipe" ? true : false
                        }
                        linkIcon={IoAddCircleOutline}
                        linkText={t("createrecipe")}
                        linkHref="/createrecipe"
                        onClick={onClose}
                      />
                      <NavLink
                        isActive={
                          cleanedUpPathname === "weekplan" ? true : false
                        }
                        linkIcon={IoCalendarNumberOutline}
                        linkText={t("weekplan")}
                        linkHref="/weekplan"
                        onClick={onClose}
                      />
                      <NavLink
                        isActive={
                          cleanedUpPathname === "shoppinglist" ? true : false
                        }
                        linkIcon={IoCartOutline}
                        linkText={t("shoppinglist")}
                        linkHref="/shoppinglist"
                        onClick={onClose}
                      />
                      <HStack w="100%">
                        <NavLink
                          isActive={
                            cleanedUpPathname === "chatrooms" ? true : false
                          }
                          linkIcon={IoChatbubblesOutline}
                          linkText={t("chatrooms")}
                          linkHref="/chatrooms"
                          onClick={onClose}
                        />
                        <Badge />
                      </HStack>
                      <NavLink
                        isActive={
                          cleanedUpPathname === "profile" ? true : false
                        }
                        linkIcon={IoPersonOutline}
                        linkText={t("profile")}
                        linkHref={{
                          pathname: `/profile/${userData.user.id}`,
                          param: userData.user.id,
                        }}
                        onClick={onClose}
                      />
                      <NavLink
                        isActive={
                          cleanedUpPathname === "help" ? true : false
                        }
                        linkIcon={IoHelpCircleOutline}
                        linkText={t("help")}
                        linkHref={{
                          pathname: `/help`,
                        }}
                        onClick={onClose}
                      />
                    </VStack>
                  </VStack>
                ) : (
                  <Flex
                    flexDir="column"
                    h="90%"
                    w="100%"
                    justifyContent="center"
                    alignItems="center"
                    p={2}
                  >
                    <PrimaryButton
                      as={Reachlink}
                      to={"/login"}
                      onClick={onClose}
                      w={"100%"}
                      mb={5}
                      leftIcon={<MdLogin />}
                    >
                      {t("login")}
                    </PrimaryButton>
                    <Link
                      mt={8}
                      as={Reachlink}
                      to={"help"}
                      _hover={{ textDecoration: "none" }}
                      onClick={onClose}
                    >
                      {t("helpNeeded")}
                    </Link>
                  </Flex>
                )}
              </>
            </DrawerBody>
            <DrawerFooter
              align="center"
              borderTopWidth="1px"
              pb={"11px"}
              pt={"11px"}
            >
              <VStack spacing={5} margin="auto">
                <Flex>
                  <Text fontSize="xs">
                    &copy; {new Date().getFullYear()} MP
                  </Text>
                  <Box as="span" fontSize="xs" px={1}>
                    &bull;
                  </Box>
                  <Link
                    fontSize="xs"
                    as={Reachlink}
                    to="/privacy"
                    onClick={onClose}
                  >
                    {t("privacyShort")}
                  </Link>
                  <Box as="span" fontSize="xs" px={1}>
                    &bull;
                  </Box>
                  <Link
                    fontSize="xs"
                    as={Reachlink}
                    to="/legal"
                    onClick={onClose}
                  >
                    {t("legal")}
                  </Link>
                </Flex>
              </VStack>
            </DrawerFooter>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  );
}

export default UserMenu;

UserMenu.propTypes = {
  isDesktop: PropTypes.bool,
  cleanedUpPathname: PropTypes.string,
};
