import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FaAngleLeft } from "react-icons/fa";
import {
  useColorModeValue,
  Box,
  HStack,
  IconButton,
  Text,
} from "@chakra-ui/react";
import UserMenu from "./UserMenu";

// MOBILE NAVBAR
const HeaderMobile = ({ cleanedUpPathname, title }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const headingColor = useColorModeValue("white", "white");

  /* We got 3 different headers:
 - one transparent with apptitle and drawer for home (default return).
 - one transparent with back and drawer for a singlerecipe and createrecipe (svg is just in background).
 - many green background svgs in same heights ?.
 */

  /*  Important Note - that a SVG is renderd correctly on portrait and landscape mode
       following values have to be set into the .svg file
       width="100%"
       height="100%"
       preserveAspectRatio="none"
  */

  // header for landing and home
  if (cleanedUpPathname === "" || cleanedUpPathname === "home") {
    return (
      <Box
        bg={"linear-gradient(0deg, transparent, rgb(0 0 0 / 60%) 100%)"}
        width="100vw"
        id="header"
        color={headingColor}
        pos="absolute"
        zIndex="10"
        display="flex"
        justifyContent="flex-end"
      >
        <Box pr={4}>
          <UserMenu cleanedUpPathname={cleanedUpPathname} />
        </Box>
      </Box>
    );
  } else if (cleanedUpPathname === "recipe") {
    // header for recipe routes
    return (
      <Box
        bg={"linear-gradient(0deg, transparent, rgb(0 0 0 / 60%) 100%)"}
        width="100vw"
        id="header"
        color={headingColor}
        pos="absolute"
        zIndex="10"
      >
        <HStack justifyContent="space-between">
          <IconButton
            pl="0"
            variant="ghost"
            _hover={{ background: "transparent" }}
            _active={{ background: "transparent" }}
            onClick={() => history.goBack()}
            icon={<FaAngleLeft />}
            size="lg"
            fontSize="25px"
            aria-label="Back"
          />
          <Box pr={4}>
            <UserMenu cleanedUpPathname={cleanedUpPathname} />
          </Box>
        </HStack>
      </Box>
    );
  }

  // default header
  return (
    <Box
      pos="relative"
      width="100vw"
      id="header"
      zIndex="10"
      color={headingColor}
      bgColor={"brand.600"}
    >
      <HStack justifyContent="space-between">
        <IconButton
          pl="0"
          variant="ghost"
          _hover={{ background: "transparent" }}
          _active={{ background: "transparent" }}
          onClick={() => history.goBack()}
          icon={<FaAngleLeft />}
          size="lg"
          fontSize="25px"
          aria-label="Back"
        />
        <Text
          as="header"
          fontSize={"18px"}
          fontWeight="600"
          pl={4}
          py={2}
          id="heading"
          color="white"
          isTruncated
        >
          {t(title)}
        </Text>
        <Box pr={4}>
          <UserMenu cleanedUpPathname={cleanedUpPathname} />
        </Box>
      </HStack>
    </Box>
  );
};

export default HeaderMobile;

HeaderMobile.propTypes = {
  cleanedUpPathname: PropTypes.string,
  title: PropTypes.string,
};
