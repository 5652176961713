import { useState, useEffect } from "react";
import { postRequest } from "../utils/Api";
import { getOffset, cookieExist } from "../utils/Utils";
import ErrorMessage from "../utils/ErrorMessage";

export default function useCheckLoggedIn() {
  const [userData, setUserData] = useState(undefined);
  const [isUserLoading, setUserLoading] = useState(true);

  useEffect(() => {
    if (cookieExist("refreshtoken_set")) {
      const jstz = require("jstimezonedetect");
      const timezone = jstz.determine().name();
      const offset = getOffset();
      const device = navigator.userAgent;
      const language = window.localStorage.getItem("i18nextLng");
      const payload = { offset, timezone, device, language };

      const request = postRequest(
        "/auth/check",
        payload,
        function (res) {
          if (res && res.status !== 204) {
            setUserData({
              user: res.data.user,
            });
            localStorage.setItem(
              "messageCounter",
              res.data.user.messageCounter.toString()
            );
          }
          setUserLoading(false);
        },
        function (err) {
          ErrorMessage(err)
          setUserLoading(false);
        }
      );

      return function () {
        request.cancel();
      };
    } else {
      setUserLoading(false);
      setUserData(undefined);
    }
  }, []);

  return {
    userData,
    setUserData,
    isUserLoading,
  };
}
