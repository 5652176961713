import { useState } from "react";

export default function useRecipecache() {
  // included default values for some components
  // const [cache, setCache] = useState({});
  const [cache, setCache] = useState({
    cacheTitle: "",
    cacheDescription: "",
    cacheAudience: "",
    cacheDifficulty: "Easy",
    cacheDuration: 10,
    cacheIngredient: "",
    cacheLot: 10,
    cacheUnit: "",
    cacheIngredients: [],
    cachePortion: 4,
    cachePrepStep: "",
    cachePrepSteps: [],
    cacheTag: "",
    cacheTags: [],
    cacheImageId: "",
    cacheImageUrl: "",
  });

  return {
    cache,
    setCache,
  };
}
