import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Box, useColorModeValue, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import HeaderBackgroundSmall from "../assets/HeaderBackground_small.svg";
import HeaderBackground from "../assets/HeaderBackground.svg";
import sizeContext from "../context/sizeContext";

const ContentBox = (props) => {
  const { windowSize } = useContext(sizeContext);
  const bgDark = windowSize.isMobile ? "gray.800" : "gray.700";
  const bg = useColorModeValue("white", bgDark);
  const { t } = useTranslation();
  const borderSizeXl = "53vh";
  const borderSizeLg = "40vh";
  const borderSizeSm = "30px";

  return (
    <Box
      bg={bg}
      shadow="lg"
      rounded={{ base: "none", lg: "lg" }}
      w={{ base: "100%", lg: "50%" }}
      mx="auto"
      id="contentBox"
      minHeight={windowSize.isMobile ? "100%" : null}
      maxW={{ base: null, lg: "805px" }}
    >
      {props.showBorder ? (
        <Box
          bgImage={
            props.borderSize === "lg" || props.borderSize === "xl"
              ? HeaderBackground
              : HeaderBackgroundSmall
          }
          bgSize={"cover"}
          bgRepeat={"no-repeat"}
          height={
            props.borderSize === "lg"
              ? borderSizeLg
              : props.borderSize === "xl"
                ? borderSizeXl
                : borderSizeSm
          }
          position="relative"
          filter="drop-shadow(0px 1px 2px rgb(0 0 0 / 0.4))"
          zIndex={10}
          mb="8px"
        />
      ) : null}
      {props.showHeading ? (
        <Box textAlign="center">
          <Heading as="h1" size="lg" fontWeight="semibold" p={4} id="heading">
            {t(props.title)}
          </Heading>
        </Box>
      ) : null}
      <Box
        bg={bg}
        overflow={"hidden"}
        p={props.p ? props.p : "0"}
        mt={
          props.borderSize === "lg" && props.showBorder
            ? "-" + borderSizeLg
            : props.borderSize === "xl" && props.showBorder
              ? "-" + borderSizeXl
              : null
        }
        pb={windowSize.isMobile ? "60px" : null}
        rounded={{ base: "none", lg: "lg" }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

export default ContentBox;

ContentBox.propTypes = {
  showBorder: PropTypes.bool,
  borderSize: PropTypes.string,
  showHeading: PropTypes.bool,
  title: PropTypes.string,
  p: PropTypes.number,
  children: PropTypes.node,
};
