import React from "react"
import PropTypes from 'prop-types';
import RecipeCacheContext from "../recipeCacheContext";
import useRecipeCache from "../../hooks/useRecipeCache";
import ScrollPositionContext from "../scrollPositionContext";
import useScrollPosition from "../../hooks/useScrollPosition";


const CacheRecipesScrollProviders = ({ children }) => {
    /* 
        Make a Provider component that takes children instead of putting directly components in the Provider like this:
        So we can call the context in every component without causing a rerender
    */
    const { cache, setCache } = useRecipeCache();
    const { scrollPosition, setScrollPosition } = useScrollPosition();

    return (
        <RecipeCacheContext.Provider value={{ cache, setCache }}>
            <ScrollPositionContext.Provider value={{ scrollPosition, setScrollPosition }}>
                {children}
            </ScrollPositionContext.Provider>
        </RecipeCacheContext.Provider>
    )
}

export default CacheRecipesScrollProviders

CacheRecipesScrollProviders.propTypes = {
    children: PropTypes.node,
};

