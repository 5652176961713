import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link as Reachlink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useColorModeValue,
  Button,
  Box,
  Flex,
  Link,
  Heading,
  Icon,
} from "@chakra-ui/react";
import { FaSearch } from "react-icons/fa";
import { goToHome } from "../utils/Utils";
import { MdLogin } from "react-icons/md";
import SpoonyCircle from "../assets/SpoonyCircle";
import userContext from "../context/userContext";
import UserMenu from "./UserMenu";
import { PrimaryButton } from "../theme/components/PrimaryButton";

const HeaderDesktop = ({ pathname, cleanedUpPathname }) => {
  const { userData } = useContext(userContext);
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Box
      bg={useColorModeValue("white", "gray.700")}
      px={4}
      width={"100vw"}
      borderStyle="solid"
      borderBottomWidth="1px"
      borderColor={useColorModeValue("gray.50", "gray.600")}
      id="header"
    >
      <Flex alignItems={"center"} direction={"row"} width="full" align="center">
        <Link
          as={Reachlink}
          to="/"
          onClick={(e) => {
            goToHome(e, pathname, history);
          }}
          fontSize="lg"
          fontWeight="bold"
          px={4}
          rounded={"md"}
          _hover={{
            textDecoration: "none",
          }}
          color="#006700"
        >
          <Flex alignItems="center">
            <Icon as={SpoonyCircle} w={10} h={10} ml={4} />
            <Heading
              as="cite"
              size="lg"
              fontWeight="semibold"
              color="brand.600"
              mt={1}
              ml={1}
            >
              {t("appTitle")}
            </Heading>
          </Flex>
        </Link>
        <Flex w="100%" justifyContent="flex-end" alignItems="center" px={4}>
          <Button
            as={Reachlink}
            to={"/search"}
            colorScheme="black"
            variant="link"
            fontWeight="normal"
            leftIcon={<FaSearch />}
            p={4}
            size="lg"
          >
            {t("search")}
          </Button>
          {/* If user is logged in, load avater, else load login sign up */}
          {userData && userData.user ? (
            <UserMenu isDesktop={true} cleanedUpPathname={cleanedUpPathname} />
          ) : (
            <>
              <Link
                as={Reachlink}
                to={"/login"}
                _hover={{ textDecoration: "none" }}
                p={2}
                mx={4}
              >
                <PrimaryButton w="100%" px={14} leftIcon={<MdLogin />}>
                  {t("login")}
                </PrimaryButton>
              </Link>
              <UserMenu isDesktop={true} />
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
export default HeaderDesktop;

HeaderDesktop.propTypes = {
  pathname: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  cleanedUpPathname: PropTypes.string,
};
