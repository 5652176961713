import React from "react"

const SpoonyCircle = (props) => (
    <svg width={"41"} height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="20.5" cy="20.5" r="20.5" fill="white" />
        <path d="M24.9805 19.547C24.492 19.2267 23.9875 18.8744 23.427 18.458C19.407 15.4671 18.5982 12.0998 18.5982 12.0998C18.5982 12.0998 15.255 15.2669 17.8495 18.2378C20.448 21.2087 22.498 18.7823 24.3839 19.9995C24.7002 20.2037 25.0646 20.52 25.493 20.8763C31.3347 25.7051 27.6271 30.1974 25.0886 31.2705C23.6872 31.8631 22.1497 32.1914 20.5361 32.1914C14.0778 32.1914 8.84473 26.9583 8.84473 20.5C8.84473 17.8774 11.1109 17.0566 15.9396 21.3168C17.6213 22.8022 15.6233 25.4248 19.0947 27.3066C22.5621 29.1844 24.9164 25.2246 24.9164 25.2246C24.9164 25.2246 21.453 25.1765 17.6533 21.9093C15.1389 19.7512 14.7425 19.4229 13.9057 18.5781C10.106 14.7424 13.6574 10.7385 15.8636 9.77751C17.293 9.15291 18.8745 8.80457 20.5361 8.80457C26.514 8.80457 31.8312 13.2569 32.1435 19.0826C32.3236 22.4178 30.4578 23.1506 24.9805 19.547Z" fill="#009444" />
        <path d="M23.0506 17.9054C19.1308 14.7864 18.5942 12.0998 18.5942 12.0998C18.5942 12.0998 15.251 15.2669 17.8455 18.2378C20.444 21.2087 22.5101 19.0345 24.2317 20.3839C30.3577 25.1926 27.6271 30.1974 25.0886 31.2705C23.6872 31.8631 22.1497 32.1914 20.5361 32.1914C14.0778 32.1914 8.84473 26.9583 8.84473 20.5C8.84473 18.498 10.5784 17.2528 15.3791 21.4729C17.333 23.1906 15.4031 25.4808 19.0947 27.3066C22.6342 29.0523 24.9164 25.2246 24.9164 25.2246C24.9164 25.2246 21.6893 25.0764 17.8896 21.8133C15.3751 19.6551 15.0508 19.1827 14.3061 18.2578C10.9988 14.1698 13.6574 10.7425 15.8676 9.77751C17.297 9.15291 18.8785 8.80457 20.5401 8.80457C26.518 8.80457 31.8352 13.2569 32.1475 19.0826C31.9953 22.7021 26.2337 20.4399 23.0506 17.9054Z" fill="#39B54A" />
    </svg>
);

export default SpoonyCircle