import VideoConverter from "convert-video";

export function getOffset() {
  const offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    ":" +
    ("00" + (o % 60)).slice(-2)
  );
}

export function getRandomHexColor() {
  function padHex(val) {
    let hex = val.toString(16);
    if (hex.length < 2) {
      hex = "0" + hex;
    }
    return hex;
  }

  const r = Math.floor(Math.random() * 256); // Generate a random integer between 0 and 255 for the red channel
  const g = Math.floor(Math.random() * 256); // Generate a random integer between 0 and 255 for the green channel
  const b = Math.floor(Math.random() * 256); // Generate a random integer between 0 and 255 for the blue channel
  const hexColor = `#${padHex(r)}${padHex(g)}${padHex(b)}`; // Convert the RGB values to their hex equivalents and concatenate them into a single string
  return hexColor;
}

export function rewriteMetaTags(data) {
  const location = window.location.href;

  const title = data.title;
  const description = data.description;
  const keywords = data.keywords;
  const image = data.image;
  const themeColor = data.themeColor;

  if (title && title !== "") {
    document.title = title;
    document
      .querySelector('meta[property="og:title"]')
      .setAttribute("content", title);
  }
  if (description && description !== "") {
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", description);
    document
      .querySelector('meta[property="og:description"]')
      .setAttribute("content", description);
  }
  if (keywords && keywords !== "") {
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", keywords);
  }
  document
    .querySelector('meta[property="og:url"]')
    .setAttribute("content", location);

  if (image && image._id !== "" && image._id !== "na") {
    const imageUrl =
      "https://spoony.cooking/images/preview/" + image._id + ".jpeg";
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute("content", imageUrl);
  } else {
    const imageUrl = "https://www.spoony.cooking/preview.jpeg";
    document
      .querySelector('meta[property="og:image"]')
      .setAttribute("content", imageUrl);
  }

  if (themeColor && themeColor !== "") {
    document
      .querySelector('meta[name="theme-color"]')
      .setAttribute("content", themeColor);
  }
}

export function getIndexOf(arrary, search) {
  for (let i = 0; i < arrary.length; i++) {
    let subData = arrary[i]._id;
    let index = subData.indexOf(search);
    if (index > -1) {
      return i;
    }
  }
}

export function imageHeight(windowSize) {
  // 114 Pixel => Header + Footer + Padding Bootom from Feed
  let height;
  if (windowSize) {
    height = (windowSize.height + 50) * 0.4;
  }
  return height;
}

export function imageHeightFeed(windowSize) {
  let height = windowSize.height;
  if (!windowSize.isMobile) {
    height = height * 0.6;
  }
  if (height < 450) {
    height = 450;
  }

  return height;
}

export function timeDifference(current, previous, t) {
  let msPerMinute = 60 * 1000;
  let msPerHour = msPerMinute * 60;
  let msPerDay = msPerHour * 24;
  let msPerMonth = msPerDay * 30;
  let msPerYear = msPerDay * 365;

  let elapsed = current - previous;

  if (elapsed < msPerMinute) {
    let seconds = Math.round(elapsed / 1000);
    if (seconds === 0) seconds = 1;
    if (seconds > 1) {
      return seconds + " " + t("secondsAgo");
    } else {
      return seconds + " " + t("secondAgo");
    }
  } else if (elapsed < msPerHour) {
    let minutes = Math.round(elapsed / msPerMinute);
    if (minutes > 1) {
      return minutes + " " + t("minutesAgo");
    } else {
      return minutes + " " + t("minuteAgo");
    }
  } else if (elapsed < msPerDay) {
    let hours = Math.round(elapsed / msPerHour);
    if (hours > 1) {
      return hours + " " + t("hoursAgo");
    } else {
      return hours + " " + t("hourAgo");
    }
  } else if (elapsed < msPerMonth) {
    let days = Math.round(elapsed / msPerDay);
    if (days > 1) {
      return days + " " + t("daysAgo");
    } else {
      return days + " " + t("dayAgo");
    }
  } else if (elapsed < msPerYear) {
    let months = Math.round(elapsed / msPerMonth);
    if (months > 1) {
      return months + " " + t("monthsAgo");
    } else {
      return months + " " + t("monthAgo");
    }
  } else {
    let years = Math.round(elapsed / msPerYear);

    if (isNaN(years)) {
      return "2000 " + t("yearsAgo");
    }

    if (years > 1) {
      return years + " " + t("yearsAgo");
    } else {
      return years + " " + t("yearAgo");
    }
  }
}

export function validateEmail(email) {
  const re =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  return re.test(email);
}

export function goToHome(e, pathname, history) {
  let homeActive = false;
  if (pathname === "/" || pathname === "/home") {
    homeActive = true;
  }

  if (homeActive) {
    e.preventDefault();
    history.go(0);
  }
}

export async function convertVideo(file) {
  // .mov video files has to be converted to mp4!
  if (file && file.type === "video/quicktime") {
    const sourceVideoFile = file;
    const targetVideoFormat = "mp4";
    const converted = await VideoConverter.convert(
      sourceVideoFile,
      targetVideoFormat
    );
    const newFileName = file.name.substr(0, file.name.length - 3) + "mp4";
    const blob = await fetch(converted.data).then((r) => r.blob());
    const newFile = new File([blob], newFileName, { type: "video/mp4" });
    return newFile;
  }
  return file;
}

export function fieldValidation(value, field) {
  let valueIsLongEnough;
  let valueIsHighEnough;
  let valueIsShortEnough;

  switch (field) {
    case "title":
      if (!value) return "errorTitleRequired";

      valueIsLongEnough = value.length >= 3;
      valueIsShortEnough = value.length <= 455;

      if (!valueIsLongEnough) {
        return "errorTitleNotValid";
      } else if (!valueIsShortEnough) {
        return "errorTitleNotValid";
      }
      break;
    case "description":
      if (!value) return "errorDescriptionRequired";

      valueIsLongEnough = value.length >= 3;
      valueIsShortEnough = value.length <= 1000;

      if (!valueIsLongEnough) {
        return "errorDescriptionNotValid";
      } else if (!valueIsShortEnough) {
        return "errorDescriptionNotValid";
      }
      break;
    case "portion":
      if (!value) return "errorPortionRequired";

      valueIsHighEnough = value > 0;
      if (!valueIsHighEnough) {
        return "errorPortionNotValid";
      }
      break;
    case "tags":
      if (value.length <= 0) return "errorNotEnoughTags";
      break;
    case "prepStep":
      if (value.length <= 0) {
        return "errorPrepStepRequired";
      } else if (value.length >= 1000) {
        return "errorPrepStepNotValid";
      }

      break;
    case "prepSteps":
      if (value.length <= 0) return "errorNotEnoughPrepSteps";
      break;
    case "ingredients":
      if (value.length <= 0) return "errorNotEnoughIngredients";
      break;
    case "image":
      if (!value) return "errorNoImage";
      break;

    default:
      return undefined;
  }
  return undefined;
}

export function submitValidation(formData) {
  if (!formData.title) return false;
  if (!formData.description) return false;
  if (formData.portion <= 0) return false;
  if (formData.ingredients.length <= 0) return false;
  if (formData.prepSteps.length <= 0) return false;
  if (formData.tags.length <= 0) return false;
  if (formData.imageUrl.indexOf("dummy") >= 0) return false;

  return true;
}

export function ingredientValidation(ingredient, lot, unit, isSubmit = false) {
  if (ingredient === "" && isSubmit) {
    return "errorIngredientRequired";
  } else if (ingredient.length > 1000) {
    return "errorIngredientNotValid";
  }
  if (lot === "" && isSubmit) {
    return "errorLotRequired";
  } else if (lot > 1000) {
    return "errorLotMax";
  } else if (lot < 0) {
    return "errorLotMin";
  }
  if (unit === "" && isSubmit) {
    return "errorUnitRequired";
  } else if (unit.length > 455) {
    return "errorUnitLength";
  }

  return true;
}

export function getPath() {
  // useLocation is not working, because the value dont gets updated
  const path = window.location.pathname;
  return path;
}

export function cookieExist(cookiename) {
  const myCookie = getCookie(cookiename);

  if (myCookie === null) {
    return false;
  } else {
    return true;
  }
}

export function getCookie(name) {
  let dc = document.cookie;
  let prefix = name + "=";
  let begin = dc.indexOf("; " + prefix);
  let end = dc.length; // default to end of the string

  // found, and not in first position
  if (begin !== -1) {
    // exclude the "; "
    begin += 2;
  } else {
    //see if cookie is in first position
    begin = dc.indexOf(prefix);
    // not found at all or found as a portion of another cookie name
    if (begin === -1 || begin !== 0) return null;
  }

  // if we find a ";" somewhere after the prefix position then "end" is that position,
  // otherwise it defaults to the end of the string
  if (dc.indexOf(";", begin) !== -1) {
    end = dc.indexOf(";", begin);
  }

  //eslint-disable-next-line
  return decodeURI(dc.substring(begin + prefix.length, end)).replace(/\"/g, "");
}

export function isiOS() {
  let check =
    (/iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (navigator.userAgent === "MacIntel" && navigator.maxTouchPoints > 1)) &&
    !window.MSStream;

  return check;
}

/**
 * We don't want to display the whole email address as username after registering via 3rd party.
 * It takes an email address as a string, splits it at the @ symbol, and returns the first part of the
 * email address with all non-alphanumeric characters replaced with spaces
 * @param {string} email - string - the email address to parse
 * @returns The username of the email address.
 */
export const parseUsername = (email) => {
  const parsedUsername = email.split("@");
  return parsedUsername[0].replace(/[^a-zA-Z0-9 ]/g, " ");
};
