import React from "react"
import { Global } from "@emotion/react"
const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Raleway-Regular';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url(https://spoony.cooking/assets/fonts/Raleway-Regular.ttf) format('truetype');
      }
      @font-face {
        font-family: 'Montserrat-Regular';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url(https://spoony.cooking/assets/fonts/Montserrat-Regular.ttf) format('truetype');
     }
      `}
  />
)
export default Fonts