import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useLayoutEffect(() => {
        if (pathname !== "/" && pathname !== "/home") {
            window.scrollTo(0, 0);
            const content = document.querySelector(".content");
            if (content) {
                content.scrollTo(0, 0);
            }
        }
    }, [pathname]);

    return null;
}