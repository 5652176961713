import { useState, useLayoutEffect } from "react";

export default function useWindowResize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    isMobile: false,
  });

  useLayoutEffect(() => {
    let id;

    function isMobile(width) {
      let isMobile = false;
      if (width < 860) {
        isMobile = true;
      }
      return isMobile;
    }

    function checkSize() {
      let width = window.innerWidth;
      let height = window.innerHeight;

      if (
        width &&
        height &&
        (width !== windowSize.width || height !== windowSize.height)
      ) {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
          isMobile: isMobile(window.innerWidth)
        });
      }
    }

    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      clearInterval(id);
      checkSize();

      // iOS renders Resizings with a Delay, so we need to set different timeouts to recalculated windows Size
      id = setInterval(function () {
        checkSize();
        clearInterval(id);
      }, 500);
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => {
      clearInterval(id);
      window.removeEventListener("resize", handleResize);
    };
  }, [windowSize]); // Empty array ensures that effect is only run on mount

  return { windowSize };
}
