import React, { Fragment, useContext } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import ScrollToTop from "./components/ScrollToTop";
import Layout from "./layout/Layout";
import { useTranslation } from "react-i18next";
import { rewriteMetaTags } from "./utils/Utils";
import CacheRecipeScrollProviders from "./context/BatchProviders/CacheRecipeScrollProviders";
import LoadingCircle from "./components/LoadingCircle";
import userContext from "./context/userContext";
import loadable from "@loadable/component";

const CreateRecipe = loadable(() =>
  import(/* webpackChunkName: "CreateRecipe" */ "./pages/recipes/CreateRecipe")
);

const UpdateRecipe = loadable(() =>
  import(/* webpackChunkName: "UpdateRecipe" */ "./pages/recipes/UpdateRecipe")
);

const Register = loadable(() =>
  import(/* webpackChunkName: "Register" */ "./pages/user/Register")
);

const Login = loadable(() =>
  import(/* webpackChunkName: "Login" */ "./pages/user/Login")
);

const NewPassword = loadable(() =>
  import(/* webpackChunkName: "NewPassword" */ "./pages/user/NewPassword")
);

const ResetPassword = loadable(() =>
  import(/* webpackChunkName: "ResetPassword" */ "./pages/user/ResetPassword")
);

const ForgottenPassword = loadable(() =>
  import(
    /* webpackChunkName: "ForgottenPassword" */ "./pages/user/ForgottenPassword"
  )
);

const Profile = loadable(() =>
  import(/* webpackChunkName: "Profile" */ "./pages/user/Profile")
);

const SingleRecipePage = loadable(() =>
  import(
    /* webpackChunkName: "SingleRecipePage" */ "./pages/recipes/SingleRecipe"
  )
);

const Search = loadable(() =>
  import(/* webpackChunkName: "Search" */ "./pages/Search.js")
);

const Chat = loadable(() =>
  import(/* webpackChunkName: "Chat" */ "./pages/chat/Chat.js")
);

const NewChat = loadable(() =>
  import(/* webpackChunkName: "NewChat" */ "./pages/chat/NewChat.js")
);

const Chatrooms = loadable(() =>
  import(/* webpackChunkName: "Chatrooms" */ "./pages/chat/Chatrooms.js")
);

const Weekplan = loadable(() =>
  import(/* webpackChunkName: "Weekplan" */ "./pages/Weekplan")
);

const ShoppingList = loadable(() =>
  import(/* webpackChunkName: "ShoppingList" */ "./pages/Shoppinglist")
);

const Home = loadable(() =>
  import(/* webpackChunkName: "Home" */ "./pages/feed/Home")
);

const Landing = loadable(() =>
  import(/* webpackChunkName: "Landing" */ "./pages/feed/Landing")
);

const Privacy = loadable(() =>
  import(/* webpackChunkName: "Privacy" */ "./pages/Privacy")
);

const Legal = loadable(() =>
  import(/* webpackChunkName: "Legal" */ "./pages/Legal")
);

const NotFound = loadable(() =>
  import(/* webpackChunkName: "NotFound" */ "./pages/PageNotFound")
);

const UserAgreement = loadable(() =>
  import(/* webpackChunkName: "UserAgreement" */ "./pages/UserAgreement")
);

const Help = loadable(() =>
  import(/* webpackChunkName: "Help" */ "./pages/Help")
);

const Thanks = loadable(() =>
  import(/* webpackChunkName: "Thanks" */ "./pages/Thanks")
);


function App() {
  const { isUserLoading } = useContext(userContext);
  const { t } = useTranslation();

  rewriteMetaTags({
    title: t("appTitle"),
    description: t("appDescription"),
    keywords: t("appKeywoards"),
  });

  // important, otherwise every Use Effects gets triggered twice
  if (isUserLoading) {
    return <LoadingCircle />;
  }

  return (
    <Fragment>
      <BrowserRouter>
        <CacheRecipeScrollProviders>
          <Layout>
            <ScrollToTop />
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/legal" component={Legal} />
              <Route exact path="/useragreement" component={UserAgreement} />
              <Route exact path="/help" component={Help} />
              <Route exact path="/thanks" component={Thanks} />
              <Route path="/register" component={Register} />
              <Route path="/login" component={Login} />
              <Route path="/forgottenpassword" component={ForgottenPassword} />
              <Route path="/resetpassword" component={ResetPassword} />
              <Route
                path="/recipe/:title/:recipeId/:image"
                component={SingleRecipePage}
              />
              <Route path="/search" component={Search} />
              <Route path="/profile/:userId" component={Profile} />
              <PrivateRoute exact path="/home" component={Home} />
              <PrivateRoute
                exact
                path="/createrecipe"
                component={CreateRecipe}
              />
              <PrivateRoute
                exact
                path="/updaterecipe/:recipeId"
                component={UpdateRecipe}
              />
              <PrivateRoute exact path="/newpassword" component={NewPassword} />
              <PrivateRoute exact path="/chatrooms" component={Chatrooms} />
              <PrivateRoute exact path="/chat/:chatroomId" component={Chat} />
              <PrivateRoute exact path="/newchat" component={NewChat} />
              <PrivateRoute exact path="/weekplan" component={Weekplan} />
              <PrivateRoute
                exact
                path="/shoppinglist"
                component={ShoppingList}
              />
              <Route component={NotFound} />
            </Switch>
          </Layout>
        </CacheRecipeScrollProviders>
      </BrowserRouter>
    </Fragment>
  );
}
export default App;
