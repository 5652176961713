import Icon from "@chakra-ui/icon";
import PropTypes from "prop-types";
import { HStack, LinkBox, LinkOverlay, Text } from "@chakra-ui/react";
import React from "react";
import { Link as Reachlink } from "react-router-dom";

const NavLink = ({ isActive, linkIcon, linkText, linkHref, onClick }) => {
  return (
    <LinkBox
      w="100%"
      py={2}
      px={6}
      bgGradient={
        isActive ? "linear(to-r, brand.600, brand.600, brand.300)" : null
      }
      borderRadius="lg"
      alignItems="center"
      filter={isActive ? "drop-shadow(1px 2px 2px rgb(0 0 0 / 0.4))" : null}
      onClick={onClick}
    >
      <LinkOverlay as={Reachlink} to={linkHref}>
        <HStack alignItems="center" color={isActive ? "white" : ""}>
          <Icon as={linkIcon} w="6" h="6" />
          <Text fontSize="lg" lineHeight="1.0">
            {linkText}
          </Text>
        </HStack>
      </LinkOverlay>
    </LinkBox>
  );
};

export default NavLink;

NavLink.propTypes = {
  isActive: PropTypes.bool,
  linkIcon: PropTypes.func,
  linkText: PropTypes.string,
  linkHref: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClick: PropTypes.func,
};
