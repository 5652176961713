import React, { useRef, useEffect, useContext, useState } from "react";
import { Box } from "@chakra-ui/react";
import socketIOClient from "socket.io-client";
import UserContext from "../../context/userContext";
import { getPath } from "../../utils/Utils";

const newCounterEvent = "newCounter"; // Name of the event
const API_URL =
  process.env.REACT_APP_NODE_SERVER.trim() || "http://localhost:5000";

const UnreadMessagesBadge = () => {
  const messageBox = useRef(null);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const socketRef = useRef();
  const { userData } = useContext(UserContext);

  function initWebsocketConnection() {
    if (userData && userData.user) {
      const userId = userData.user.id.toString();

      // Creates a WebSocket connection
      socketRef.current = socketIOClient(API_URL, {
        query: { userId },
      });
    }
  }

  function websocketListeners() {
    if (userData && userData.user && socketRef && socketRef.current) {
      // Listens for incoming messages
      socketRef.current.on(newCounterEvent, (data) => {
        const pathname = getPath();
        if (pathname.indexOf("/chat/") < 0) {
          const counter = parseInt(data.value);
          setUnreadMessages(counter);
          localStorage.setItem("messageCounter", counter.toString());
          messageBox.current.innerHtml = counter.toString();
        } else {
          const counter = 0;
          setUnreadMessages(counter);
          localStorage.setItem("messageCounter", counter.toString());
          messageBox.current.innerHtml = counter.toString();
        }
      });
    }
  }

  function getUserMessageCounter() {
    const messageCounter = localStorage.getItem("messageCounter");
    if (userData && userData.user && userData.user.messageCounter !== undefined) {
      if (messageCounter === null) {
        localStorage.setItem("messageCounter", userData.user.messageCounter.toString());
        setUnreadMessages(parseInt(userData.user.messageCounter));
      } else {
        setUnreadMessages(parseInt(localStorage.getItem("messageCounter")));
      }
      messageBox.current.innerHtml = localStorage.getItem("messageCounter");
    }
  }

  useEffect(() => {

    // initialy get counter from UserData
    getUserMessageCounter();
    // init websocket connection
    initWebsocketConnection();
    // add listeners to websocket connection
    websocketListeners();

    // Destroys the socket reference
    // when the connection is closed
    return () => {
      if (socketRef && socketRef.current) {
        socketRef.current.disconnect();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      as="span"
      variant="solid"
      bgColor="red.500"
      borderRadius="50%"
      p={"1px 7px 1px 7px"}
      verticalAlign="bottom"
      ml="-10px"
      color="white"
      zIndex="2"
      fontSize="xs"
      ref={messageBox}
      display={unreadMessages && unreadMessages > 0 ? null : "none"}
      filter="drop-shadow(1px 1px 2px rgb(0 0 0 / 0.4))"
    >
      {unreadMessages}
    </Box>
  );
};
export default UnreadMessagesBadge;
